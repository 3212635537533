<template>
    <v-container>
        <v-row v-if="$auth.canInvite == 1">
            <v-col>
                <v-card tile class="pb-4" color="box">
                    <v-card-title>
                        Einladungen
                    </v-card-title>
                    <v-card-text>
                        <v-data-table class="pb-6" :headers="cols_users" :items="$auth.invitedUsers" :items-per-page="-1" dense :hide-default-footer="true">
                            <template v-slot:top>
                                <span class="ml-2 accent--text">Eingeladene Benutzer</span>
                                <v-divider />
                            </template>
                            <template v-slot:item.invited="{ item }">
                                {{UTCtoLocal(item.invited)}}
                            </template>
                            <template v-slot:item.registered="{ item }">
                                {{UTCtoLocal(item.registered)}}
                            </template>
                        </v-data-table>
                        <v-data-table :headers="cols_token" :items="$auth.tokens" :items-per-page="-1" dense :hide-default-footer="true">
                            <template v-slot:top>
                                <span class="ml-2 accent--text">Einladungs Tokens</span>
                                <v-divider />
                            </template>
                            <template v-slot:item.token="{ item }">
                                {{item.token}} <v-btn small icon @click="copyToken(item)"><v-icon small>fas fa-copy</v-icon></v-btn>
                            </template>
                            <template v-slot:item.created="{ item }">
                                {{UTCtoLocal(item.created)}}
                            </template>
                            <template v-slot:item.expieres="{ item }">
                                <span :class="UTC(item.expieres) < Date.now() ? 'red--text' : ''">{{UTCtoLocal(item.expieres)}}</span>
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <v-btn v-if="UTC(item.expieres) < Date.now()" small icon color="accent"  @click="renewToken(item)"><v-icon small>fas fa-sync</v-icon></v-btn>
                                <v-btn small icon color="red"  @click="deleteToken(item)"><v-icon small>fas fa-trash</v-icon></v-btn>
                            </template>
                            <template v-slot:footer>
                                <v-row justify="end" align="center">
                                    <v-col cols="auto">
                                        <v-select v-model="createRole" :items="$auth.roles.filter(r => r.inviteable == 1)" :value="$auth.roles[0].ID" label="Rolle" item-text="displayname" item-value="ID" :loading="$db.loading">
                                            <template v-slot:append-outer>
                                                <v-btn tile small @click="createToken()" :loading="$db.loading" :disabled="createRole == null">Neues Token <v-icon right small>fas fa-plus</v-icon></v-btn>
                                            </template>
                                        </v-select>
                                        
                                    </v-col>
                                </v-row>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card tile class="pb-4">
                    <v-card-title>
                        Account Daten
                    </v-card-title>
                    <v-card-text>
                        <v-form ref="form" v-model="valid">
                            <v-container>
                                <v-row align="center">
                                    <v-col>
                                        <v-text-field v-model="$auth.username" label="Loginname" disabled></v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-text-field v-model="displayname" label="Anzeigename" :rules="displaynameRule" required></v-text-field>
                                    </v-col>
                                    <v-col>
                                        <axz-file-picker v-model="image" :rules="file_rules" label="Bild" accept="image/*" clearable>
                                            <v-btn v-show="image != null && typeof image == 'string'" icon @click="image = null"><v-icon>fas fa-times-circle</v-icon></v-btn>
                                        </axz-file-picker>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-text-field v-model="$auth.steamid" label="SteamID" disabled></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <span class="d-block text-body-1 pb-4">Rollen</span>
                                        <v-chip v-for="role in $auth.roles" :key="role.ID" color="accent" class="mx-2" outlined>
                                            {{role.displayname}}
                                        </v-chip>
                                    </v-col>
                                </v-row>
                                <v-row justify="end" class="mt-4">
                                    <v-btn :disabled="!valid" @click="save()">Speichern <v-icon right small>fas fa-save</v-icon></v-btn>
                                </v-row>
                            </v-container>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    data () {
        return {
            valid: false,
            file_rules: [
                v => !v || v.size < 20000000 || 'File size should be less than 20 MB!',
            ],
            displaynameRule: [
                    v => !!v,
                    v => /^[A-Za-z0-9_ ]{3,20}$/.test(v),
            ],
            cols_token: [
                { text: 'Token', value: 'token' },
                { text: 'Rolle', value: 'role' },
                { text: 'Erstellungsdatum', value: 'created' },
                { text: 'Ablaufdatum', value: 'expieres' },
                { text: 'Aktionen', value: 'actions', align: 'end', sortable: false },
            ],
            cols_users: [
                { text: 'Name', value: 'displayname' },
                { text: 'Einladungsdatum', value: 'invited' },
                { text: 'Registrierdatum', value: 'registered' },
            ],
            createRole: null,
            displayname: null,
            image: null,
        }
    },
    methods: {
        createToken(){
            this.$auth.createToken(this.createRole).then(() => {
                this.$auth.loadTokens();
            });
        },
        renewToken(item){
            if(confirm(`Token '${item.token}' wirklich erneuern?`)){
                this.$auth.renewToken(item.token).then(() => {
                    this.$auth.loadTokens();
                });
            }
        },
        deleteToken(item){
            if(confirm(`Token '${item.token}' wirklich löschen?`)){
                this.$auth.deleteToken(item.token).then(() => {
                    this.$auth.loadTokens();
                });
            }
        },
        copyToken(item){
            var copy = document.createElement("input");
            copy.style = "position: absolute;left:0;top:0;opacity: 1";
            copy.value = `https://axxez.eu/?token=${item.token}`;
            document.body.appendChild(copy);
            copy.select();
            copy.setSelectionRange(0, 99999);
            document.execCommand("copy");
            copy.remove();
        },
        async save(){
            this.$auth.update(this.displayname, typeof this.image == 'object' ? await this.$db.files.createOrUpdate(this.image != null ? this.image.identifier : null, this.image) : this.image);
        }
    },
    mounted() {
        this.$auth.loadTokens();
        this.$auth.loadInvitedUsers();

        this.$auth.status().then(() => {
            this.displayname = this.$auth.displayname;
            this.image = this.$auth.image;
        });
    }
}
</script>